<template>
    <router-view></router-view>
</template>
<script>
    import entityStoreModule from './entityStoreModule.js';
    import store from '@/store';
    import { onUnmounted } from '@vue/composition-api';

    export default {
        setup() {
            const ENTITY_APP_STORE_MODULE_NAME = 'app-entity';

            // Register module
            if (!store.hasModule(ENTITY_APP_STORE_MODULE_NAME))
                store.registerModule(ENTITY_APP_STORE_MODULE_NAME, entityStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(ENTITY_APP_STORE_MODULE_NAME)) store.unregisterModule(ENTITY_APP_STORE_MODULE_NAME);
            });
        },
    };
</script>
